<template>
  <a-modal
    :visible="visible"
    title="增加资源数量"
    @ok="handleOk"
    @cancel="handleCancel"
    width="860px"
  >
    <a-table
      style="margin-top: 15px; width: 800px"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :rowKey="(record) => record.type"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="customTitle" class="title_box">
        <span>有效时长</span>
        <span><a-checkbox @change="onChange"> 统一设为365天 </a-checkbox></span>
      </span>
      <div slot="type" slot-scope="text, record">
        <!-- {{ typeName[record.type] }} -->
        {{ resourceTypeData[record.type].name }}
      </div>
      <!-- 资源数量可填 -->
      <div slot="quantity" slot-scope="text, record" class="input_box">
        <a-input
          placeholder="请填写数量"
          v-model="record.quantity"
          :disabled="!selectedRowKeys.includes(record.type) || record.disabled"
        ></a-input>
        <span style="width: 40px; text-align: right">{{
          record.quantityUnit
        }}</span>
      </div>
      <!-- 有效时长可填 -->
      <div slot="duration" slot-scope="text, record" class="input_box">
        <a-input
          placeholder="请填写"
          v-model="record.duration"
          :disabled="!selectedRowKeys.includes(record.type)"
        ></a-input
        ><span style="width: 40px; text-align: right">天</span>
      </div>
    </a-table>
  </a-modal>
</template>

<script>
const columns = [
  {
    title: '资源类型',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: '资源数量',
    dataIndex: 'quantity',
    key: 'quantity',
    scopedSlots: { customRender: 'quantity' },
    width: 230,
  },

  {
    dataIndex: '有效时长',
    key: 'duration',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'duration' },
  },
]

export default {
  name: 'AddSource',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    tid: {
      type: 'String',
      default: '',
    },
  },
  data() {
    return {
      columns,
      data: [],
      selectedRowKeys: [],
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    /* 获取资源配置项信息 */
    getResourceTypeInfo() {
      return new Promise((resolve, reject) => {
        this.$api.getResourceTypes().then((res) => {
          if (res.code === 0) {
            this.resourceTypeData = res.data
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    async init() {
      /* 资源类型获取 */
      await this.getResourceTypeInfo().then(() => {
        let resourceTypeKey = Object.keys(this.resourceTypeData)
        this.data = resourceTypeKey.map((item, index) => {
          return {
            // id: index + 1,
            type: item,
            quantity: '1',
            quantityUnit: this.resourceTypeData[item].unit,
            duration: '0',
            durationUnit: 'DAYS',
          }
        })
      })
      // this.getTenantList()

      /* 只能设置时间的资源配置项 */
      let disabledArr = Object.keys(this.resourceTypeData).filter((item) => {
        return item.split('_')[0] === 'DURATION'
      })
      console.log(disabledArr)

      this.data.forEach((item, index) => {
        // this.$set(item, 'id', index + 1)
        if (disabledArr.includes(item.type)) {
          this.$set(item, 'disabled', true)
        }
      })
    },
    handleCancel() {
      // this.$emit('refresh')
      this.$emit('update:visible', false)
    },
    handleOk() {
      let _data = this.data.filter((item) =>
        this.selectedRowKeys.includes(item.type)
      )
      console.log(_data)
      let data = {
        orderSource: 'CALLZONE_OPN',
        resources: _data,
        tenantIds: [this.tid],
      }
      console.log(data)

      let isError = _data.every((item) => {
        return item.quantity > 0 && item.duration > 0
      })
      // console.log(isError)
      if (!isError) {
        this.$message.error('请检查资源设置输入值是否>1')
        return
      }

      this.addSource(data).then(() => {
        this.$message.success('修改成功')
        this.$emit('refresh')
      })
    },
    /* 统一设置checkbox */
    onChange({ target }) {
      // console.log(target)
      if (target.checked) {
        this.data.forEach((item) => {
          if (this.selectedRowKeys.includes(item.type)) {
            item.duration = 365
          }
        })
      } else {
        this.data.forEach((item) => {
          if (this.selectedRowKeys.includes(item.type)) {
            item.duration = ''
          }
        })
      }
    },
    onSelectChange(selectedRowKeys) {
      // console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    /* 添加资源数量 */
    addSource(data) {
      return new Promise((resolve, reject) => {
        this.$api.batchAddSourceOfTenant(data).then((res) => {
          if (res.code == 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-table-header-column {
  width: 100%;
}

/deep/ .input_double {
  display: flex;
  align-items: center;
}

.title_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input_box {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
