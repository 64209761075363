<!--
 * @Author: XSJ 13592841305@163.com
 * @Date: 2023-08-04 16:27:38
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-11-16 12:05:53
 * @FilePath: \cz-operation\src\views\tenantManage\tenantDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="title-box">
      <div class="title-top">
        <div class="">
          <img src="" alt="" />
          <div>{{ tenantInfo.name }}</div>
        </div>
        <!-- <a>返回上一级</a> -->
      </div>
      <div class="title-content">
        <div class="info">
          <div>
            租户ID：<span>{{ tenantInfo.id }}</span>
          </div>
          <div>
            创建人：<span>{{ tenantInfo.createdBy || '--' }}</span>
          </div>
          <div>
            是否跟进：<span>{{ tenantInfo.tracking ? '是' : '否' }}</span>
          </div>
          <div>
            状态：<span>{{ tenantInfo.active ? '正常' : '禁用' }}</span>
          </div>
          <div>
            创建时间：<span>{{
              tenantInfo.createdAt
                ? $moment(tenantInfo.createdAt).format('YYYY-MM-DD')
                : '--'
            }}</span>
          </div>
          <div>
            最近更新时间：<span>{{
              tenantInfo.updatedAt
                ? $moment(tenantInfo.updatedAt).format('YYYY-MM-DD hh:mm:ss')
                : '--'
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <a-tag
      :color="AuthColor[tenantInfo.authenticationStatus]"
      style="width: 100%; height: 50px"
      class="tag-box"
    >
      <div>{{ AuthName(tenantInfo.authenticationStatus) }}</div>
      <a @click="modifyAuth('auth')" v-if="tenantInfo.authenticationStatus == 2"
        >立即审核</a
      >
      <a @click="modifyAuth('modify')" v-else>修改认证信息</a>
    </a-tag>

    <a-tabs :activeKey="activeKey" @change="tabChange">
      <a-tab-pane tab="企业信息" key="1"></a-tab-pane>
      <a-tab-pane tab="资源使用" key="2"></a-tab-pane>
      <a-tab-pane tab="用户列表" key="3"></a-tab-pane>
    </a-tabs>

    <!-- 企业信息 -->
    <div v-if="activeKey == '1'" class="companyInfo">
      <div class="info-box">
        <div class="info-box-title">基本信息</div>
        <div>
          <span>名称：</span
          ><a-input
            v-model="tenantInfo.name"
            style="width: 40%"
            disabled
          ></a-input>
        </div>
        <div>
          <span>联系人：</span
          ><a-input
            v-model="tenantInfo.contacts"
            style="width: 40%"
            disabled
          ></a-input>
        </div>
        <div>
          <span>联系电话：</span
          ><a-input
            v-model="tenantInfo.phoneNumber"
            style="width: 40%"
            disabled
          ></a-input>
        </div>
        <div>
          <span>邮箱：</span
          ><a-input
            v-model="tenantInfo.email"
            style="width: 40%"
            disabled
          ></a-input>
        </div>
      </div>
      <div class="info-box">
        <div class="info-box-title">工商信息</div>
        <div>
          <span>法人代表：</span>
          <a-input
            v-if="tenantInfo.businessInformationResponse"
            v-model="tenantInfo.businessInformationResponse.legalRepresentative"
            style="width: 40%"
            disabled
          ></a-input>
          <a-input v-else style="width: 40%" disabled></a-input>
        </div>
        <div>
          <span>注册资本：</span
          ><a-input
            v-if="tenantInfo.businessInformationResponse"
            v-model="tenantInfo.businessInformationResponse.registeredCapital"
            style="width: 40%"
            disabled
          ></a-input>
          <a-input v-else style="width: 40%" disabled></a-input>
        </div>
        <div>
          <span>成立日期：</span>
          <!-- <a-date-picker
            @change="onChangeDate"
            v-model="tenantInfo.openDate"
            
            
          /> -->
          <a-input
            v-if="tenantInfo.businessInformationResponse"
            :value="
              $moment(
                tenantInfo.businessInformationResponse.establishmentDate
              ).format('YYYY-MM-DD')
            "
            style="width: 40%"
            disabled
          ></a-input>
          <a-input v-else style="width: 40%" disabled></a-input>
        </div>
        <div>
          <span>统一社会信用代码：</span
          ><a-input
            v-if="tenantInfo.businessInformationResponse"
            v-model="
              tenantInfo.businessInformationResponse.unifiedSocialCreditCode
            "
            style="width: 40%"
            disabled
          ></a-input>
          <a-input v-else style="width: 40%" disabled></a-input>
        </div>
        <div>
          <span>人员规模：</span
          ><a-input
            v-if="tenantInfo.businessInformationResponse"
            v-model="tenantInfo.businessInformationResponse.staffSize"
            style="width: 40%"
            disabled
          ></a-input>
          <a-input v-else style="width: 40%" disabled></a-input>
        </div>
        <div>
          <span>行业：</span
          ><a-input
            v-if="tenantInfo.businessInformationResponse"
            :value="
              getIndustry(tenantInfo.businessInformationResponse.industry)
            "
            style="width: 40%"
            disabled
          ></a-input>
          <a-input v-else style="width: 40%" disabled></a-input>
        </div>
        <div>
          <span>省市区：</span
          ><a-input
            v-if="tenantInfo.businessInformationResponse"
            style="width: 40%"
            :value="
              tenantInfo.businessInformationResponse.regionCode
                ? getCodeToText(
                    tenantInfo.businessInformationResponse.regionCode
                  )
                : []
            "
            disabled
          ></a-input>
          <a-input v-else style="width: 40%" disabled></a-input>
        </div>
        <div>
          <span>经营地址：</span
          ><a-textarea
            v-if="tenantInfo.businessInformationResponse"
            v-model="tenantInfo.businessInformationResponse.businessAddress"
            :rows="3"
            style="width: 70%"
            disabled
          ></a-textarea>
          <a-textarea v-else style="width: 70%" disabled></a-textarea>
        </div>
        <div>
          <span>经营范围：</span
          ><a-textarea
            v-if="tenantInfo.businessInformationResponse"
            v-model="tenantInfo.businessInformationResponse.businessScope"
            :rows="4"
            style="width: 70%"
            disabled
          ></a-textarea>
          <a-textarea v-else style="width: 70%" disabled></a-textarea>
        </div>
      </div>
      <div class="info-img">
        <div>
          <span>Logo:</span>
          <div style="width: 100px; height: 100px">
            <img :src="tenantInfo.logo" v-if="tenantInfo" />
            <img v-else src="" alt="" />
          </div>
        </div>
        <div>
          <span>营业执照:</span>
          <div style="width: 250px; height: 145px">
            <img
              v-if="tenantInfo.businessInformationResponse"
              :src="tenantInfo.businessInformationResponse.businessLicense"
            />
            <img v-else src="" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- 资源使用 -->
    <div v-if="activeKey == '2'" class="resource">
      <div class="resource_title">
        <div><span>资源使用情况</span></div>
        <a-button type="primary" @click="addResource">增加资源数量</a-button>
      </div>
      <div class="resource_box">
        <div class="item_information">
          <div>用户数</div>
          <div>
            <span>{{
              resourceData['VOLUME_BASE_COMPANY_HEADCOUNT'].usage
            }}</span
            ><span>人</span>
          </div>
          <div>
            总量{{ resourceData['VOLUME_BASE_COMPANY_HEADCOUNT'].quantity }}人
          </div>
        </div>
        <div class="item_information">
          <div>存储容量</div>
          <div>
            <span>{{
              resourceData['VOLUME_STORAGE_STORAGE'].usage | number
            }}</span
            ><span>GB</span>
          </div>
          <div>
            总量{{ resourceData['VOLUME_STORAGE_STORAGE'].quantity | number }}GB
          </div>
        </div>
        <div class="item_information">
          <div>下载流量剩余</div>
          <div>
            <span>{{
              (resourceData['USAGE_STORAGE_DOWNLOAD'].quantity -
                resourceData['USAGE_STORAGE_DOWNLOAD'].usage)
                | number
            }}</span
            ><span>GB</span>
          </div>
          <div>
            总量{{ resourceData['USAGE_STORAGE_DOWNLOAD'].quantity | number }}GB
          </div>
        </div>
        <div class="item_information">
          <div>短信剩余</div>
          <div>
            <span>{{
              resourceData['USAGE_PUSH_SMS'].quantity -
              resourceData['USAGE_PUSH_SMS'].usage
            }}</span
            ><span>条</span>
          </div>
          <div>总量{{ resourceData['USAGE_PUSH_SMS'].quantity }}条</div>
        </div>
        <div class="item_information">
          <!-- <div>短信剩余</div>
          <div>
            <span>{{
              resourceData['USAGE_PUSH_SMS'].quantity -
              resourceData['USAGE_PUSH_SMS'].usage
            }}</span
            ><span>条</span>
          </div>
          <div>总量{{ resourceData['USAGE_PUSH_SMS'].quantity }}条</div> -->
        </div>

        <div class="item_information">
          <div>表单应用</div>
          <div>
            <span>{{
              resourceData['VOLUME_BIGTABLE_FORM_APPLICATION'].usage
            }}</span
            ><span>个</span>
          </div>
          <div>
            总量{{
              resourceData['VOLUME_BIGTABLE_FORM_APPLICATION'].quantity
            }}个
          </div>
        </div>
        <div class="item_information">
          <div>流程应用</div>
          <div>
            <span>{{
              resourceData['VOLUME_BIGTABLE_PROCESS_APPLICATION'].usage
            }}</span
            ><span>个</span>
          </div>
          <div>
            总量{{
              resourceData['VOLUME_BIGTABLE_PROCESS_APPLICATION'].quantity
            }}个
          </div>
        </div>
        <div class="item_information">
          <div>移动门户应用</div>
          <div>
            <span>{{
              resourceData['VOLUME_BIGTABLE_PORTAL_APPLICATION'].usage
            }}</span
            ><span>个</span>
          </div>
          <div>
            总量{{
              resourceData['VOLUME_BIGTABLE_PORTAL_APPLICATION'].quantity
            }}个
          </div>
        </div>
        <div class="item_information">
          <div>大屏应用</div>
          <div>
            <span>{{
              resourceData['VOLUME_BIGTABLE_SCREEN_APPLICATION'].usage
            }}</span
            ><span>个</span>
          </div>
          <div>
            总量{{
              resourceData['VOLUME_BIGTABLE_SCREEN_APPLICATION'].quantity
            }}个
          </div>
        </div>
        <div class="item_information">
          <div>控制面板应用</div>
          <div>
            <span>{{
              resourceData['VOLUME_BIGTABLE_PANEL_APPLICATION'].usage
            }}</span
            ><span>个</span>
          </div>
          <div>
            总量{{
              resourceData['VOLUME_BIGTABLE_PANEL_APPLICATION'].quantity
            }}个
          </div>
        </div>
      </div>
      <a-divider></a-divider>
      <div class="resource_title">
        <div><span>高级业务订阅</span></div>
        <div></div>
      </div>
      <!-- 解决方案、应用表格（嵌套表格） -->
      <a-table
        :columns="resolveColumns"
        :data-source="resolveData"
        :pagination="false"
      >
        <template slot="planName" slot-scope="text, record">
          {{ resourceTypeData[record.resourceType].name }}
        </template>
        <template slot="aging" slot-scope="text, record">
          <div v-if="record.duration.start">
            {{ record.duration.start.split(' ')[0] }}~{{
              record.duration.end.split(' ')[0]
            }}
          </div>
          <div v-else>-</div>
        </template>
        <template slot="remainingTime" slot-scope="text, record">
          {{ record.duration.start ? getDateDiff(record.duration.end) : '-' }}
        </template>
        <a-table
          slot="expandedRowRender"
          slot-scope="text"
          :columns="appColumns"
          :data-source="text.apps"
          :pagination="false"
        >
          <template slot="appName" slot-scope="text, record">
            {{ record.name }}
          </template>
          <template slot="aging" slot-scope="text, record">
            <div v-if="record.duration.start">
              {{ record.duration.start.split(' ')[0] }}~{{
                record.duration.end.split(' ')[0]
              }}
            </div>
            <div v-else>-</div>
          </template>
          <template slot="remainingTime" slot-scope="text, record">
            {{ record.duration.start ? getDateDiff(record.duration.end) : '-' }}
          </template>
        </a-table>
      </a-table>
    </div>

    <!-- 用户列表 -->
    <div v-if="activeKey == '3'" class="userListBox">
      <div>用户数量：{{ userCount }}</div>
      <a-table
        :data-source="userList"
        :columns="columns"
        :pagination="pagination"
        @change="tableChange"
      >
        <template slot="name" slot-scope="text, record">
          <span style="margin-right: 8px">{{ text }}</span>
          <a-tag v-if="record.admin" color="blue"> 租户管理员 </a-tag>
        </template>
        <template slot="status" slot-scope="text">
          {{ text == '1' ? '正常' : '停用' }}
        </template>
        <template slot="updatedAt" slot-scope="text">
          {{ text || '--' }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="setManager(record)" v-if="!record.admin">迁移管理员</a>
          <span v-else>--</span>
        </template>
      </a-table>
    </div>

    <!-- 增加资源数量弹窗 -->
    <add-source
      :visible.sync="addResourceVisible"
      :tid="$route.query.id"
      v-if="addResourceVisible"
      @refresh="refresh"
    ></add-source>
  </div>
</template>

<script>
import { regionData, codeToText, TextToCode } from 'element-china-area-data'
// import * as data from 'element-china-area-data'
/* 0:未提交认证；1：认证通过；2认证待审核；3：认证不通过 */
import addSource from './modals/addSource.vue'
import moment from 'moment'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
  },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '账号',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '资料更新时间',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    scopedSlots: { customRender: 'updatedAt' },
  },
  {
    title: '操作人',
    dataIndex: 'nickName',
    key: 'nickName',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

const resolveColumns = [
  {
    title: '解决方案',
    dataIndex: 'planName',
    key: 'planName',
    scopedSlots: { customRender: 'planName' },
  },
  {
    title: '有效期限',
    dataIndex: 'aging',
    key: 'aging',
    scopedSlots: { customRender: 'aging' },
  },
  {
    title: '剩余时长',
    dataIndex: 'remainingTime',
    key: 'remainingTime',
    scopedSlots: { customRender: 'remainingTime' },
  },
]

const appColumns = [
  {
    title: '应用名',
    dataIndex: 'appName',
    key: 'appName',
    scopedSlots: { customRender: 'appName' },
  },
  {
    title: '有效期限',
    dataIndex: 'aging',
    key: 'aging',
    scopedSlots: { customRender: 'aging' },
  },
  {
    title: '剩余时长',
    dataIndex: 'remainingTime',
    key: 'remainingTime',
    scopedSlots: { customRender: 'remainingTime' },
  },
]

export default {
  name: 'TenantDetail',
  components: { addSource },
  data() {
    return {
      tenantInfo: {},
      activeKey: '1',
      industryList: [],
      AuthColor: {
        0: '',
        1: 'green',
        2: 'blue',
        3: 'red',
      },
      /* ----用户列表 */
      columns,
      userList: [],
      userCount: '',
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total) => `共${total}条`,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '15', '20'],
      },
      addResourceVisible: false /* 资源增加开关 */,
      resolveColumns,
      appColumns,
      resolveData: [],
      resourceData: {},
      resourceTypeData: {},
    }
  },
  created() {
    this.init()
  },
  computed: {},
  filters: {
    number: function (value) {
      console.log(value)
      return value.toFixed(2)
    },
  },
  methods: {
    /* 获取租户的信息（包括基本信息，工商信息等） */
    _getTenantInfo(tenantId) {
      return new Promise((resolve, reject) => {
        this.$api._getTenantBusinessInformation(tenantId).then((res) => {
          if (res.code == 0) {
            this.tenantInfo = res.data
            resolve()
          } else {
            reject()
          }
        })
      })
    },

    AuthName(value) {
      let data = ''
      switch (value) {
        case '0':
          data = '租户未提交认证信息'
          break
        case '1':
          // data = `提交认证信息，${this.tenantInfo.attestationDate}审核通过`
          data = `提交认证信息，审核通过`
          break
        case '2':
          data = `提交认证信息，待审核`
          break
        case '3':
          // data = `提交认证信息，${this.tenantInfo.attestationDate}审核不通过`
          data = `提交认证信息，审核不通过`
      }
      return data
    },
    getIndustry(value) {
      let data = this.industryList.filter((item) => item.dictCode == value)
      if (data.length > 0) {
        return data[0].dictLabel
      } else {
        return '-'
      }
    },
    async init() {
      // this.getTenantInfo().then(() => {
      //   this.getCodeToText(this.tenantInfo.address)
      // })
      // this._getTenantInfo(this.$route.query.id).then(() => {
      //   this.getCodeToText(this.tenantInfo.address)
      //   // this.$nextTick(() => {
      //   //   this.$forceUpdate()
      //   // })
      // })
      await this.getIndustryList()
      this.tabChange(this.activeKey)
    },
    async tabChange(activeKey) {
      this.activeKey = activeKey
      this.pagination.current = 1
      this.pagination.pageSize = 10
      if (activeKey == '3') {
        let params = {
          page: this.pagination.current,
          size: this.pagination.pageSize,
        }
        this.getUserList(params)
      } else if (activeKey == '1') {
        this._getTenantInfo(this.$route.query.id).then(() => {
          this.getCodeToText(this.tenantInfo.address)
        })
      } else if (activeKey == '2') {
        // this.getTenantSubscriptionScheme({ ttid: this.$route.query.id })
        await this.getResourceTypeInfo()
        await this.getTenantResourceCount(this.$route.query.id)
        await this.getSolutionList()
        // this.$nextTick(() => {
        //   setTimeout(() => {
        //     this.$forceUpdate()
        //   }, 3000)
        // })
        console.log(this.resolveData, '999')
      }
    },

    /* 修改认证 */
    modifyAuth(value) {
      this.$router.push({
        name: 'ModifyAuth',
        query: {
          id: this.$route.query.id,
          type: value,
        },
      })
    },

    /* 获取行业列表 */
    getIndustryList() {
      return new Promise((resolve, reject) => {
        this.$api.getIndustryList().then((res) => {
          if (res.code === 0) {
            this.industryList = res.data
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* code转省市区 */
    getCodeToText(codeArrayStr) {
      let codeArray = codeArrayStr
        .split(',')
        .map((item, index) => Number(item.slice(0, (index + 1) * 2)))
      console.log(codeArray)
      let area = ''
      switch (codeArray.length) {
        case 1:
          area += codeToText[codeArray[0]]
          break
        case 2:
          area += codeToText[codeArray[0]] + '/' + codeToText[codeArray[1]]
          break
        case 3:
          area +=
            codeToText[codeArray[0]] +
            '/' +
            codeToText[codeArray[1]] +
            '/' +
            codeToText[codeArray[2]]
          break
        default:
          break
      }
      return area
    },
    /* 分页获取用户列表 */
    getUserList(params) {
      // params = {
      //   ...params,
      //   // ttid: this.$route.query.id,
      // }
      return new Promise((resolve, reject) => {
        this.$api.getUserListByTid(this.$route.query.id, params).then((res) => {
          if (res.code == 0) {
            this.userList = res.data.content
            this.userCount = res.data.totalElements
            this.pagination.total = res.data.totalElements
            this.userList.forEach((item, index) => {
              let _index = res.data.size * res.data.number + index + 1
              this.$set(item, 'index', _index)
            })
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    tableChange({ current, pageSize }) {
      let params = {
        size: pageSize,
        page: current,
      }
      this.getUserList(params).then(() => {
        this.pagination.current = current
        this.pagination.pageSize = pageSize
      })
    },
    /* 获取租户高级业务订阅内容列表 */
    getTenantSubscriptionScheme(params) {
      return new Promise((resolve, reject) => {
        this.$api.getTenantSubscriptionScheme(params).then((res) => {
          if (res.code === 0) {
            this.resolveData = res.data
            resolve()
          } else {
            reject()
          }
        })
      })
    },

    /* 迁移管理员确认框 */
    setManager(record) {
      console.log(record)
      let params = {
        tenantId: this.tenantInfo.id,
        userId: record.userId,
      }
      let _this = this
      this.$confirm({
        title: '迁移管理员',
        content: (h) => (
          <div style="color:red;">
            确认该用户为新的租户管理员吗？原管理员将修改为普通用户。
          </div>
        ),
        onOk() {
          console.log('OK')
          _this.$api.changeManagerOfTenant(params).then((res) => {
            if (res.code == 0) {
              _this.$message.success('修改成功')
              _this.getUserList({
                size: _this.pagination.pageSize,
                page: _this.pagination.current,
              })
            }
          })
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    },

    /* 增加资源开关 */
    addResource() {
      this.addResourceVisible = true
    },
    refresh() {
      this.addResourceVisible = false
      this.init()
    },
    /* 获取剩余时长（字符串） */
    getDateDiff(endTime, startTime = moment()) {
      let sTime = moment()
      let eTime = moment(endTime)

      let duration = moment.duration(eTime.diff(sTime))

      // console.log(sTime, eTime, duration)
      let { years, months, days } = duration._data
      return `${years || 0}年${months || 0}月${days || 0}天`
    },
    /* 获取租户资源订阅使用记录 */
    getTenantResourceCount(tid) {
      return new Promise((resolve, reject) => {
        // setTimeout(() => {
        this.$api.getResourceRecord(tid).then((res) => {
          if (res.code === 0) {
            this.resourceData = res.data

            console.log(this.resolveData)

            resolve()
          } else {
            reject()
          }
        })
        // }, 3000)
      })
    },
    /* 获取解决方案列表 */
    getSolutionList() {
      return new Promise((resolve, reject) => {
        this.$api.getSolutionList().then(async (res) => {
          if (res.code === 0) {
            this.appList = res.data.content
            await this.appList.forEach(async (item) => {
              item.applications.forEach(async (_item) => {
                await this.$set(
                  _item,
                  'duration',
                  this.resourceData[item.resourceType].duration
                )
              })
            })

            await this.appList.forEach(async (item) => {
              await this.$set(
                this.resourceData[item.resourceType],
                'apps',
                item.applications
              )
            })
            this.resolveData = Object.values(this.resourceData).filter((item) =>
              item.resourceType.includes('DURATION')
            )
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 获取资源配置项信息 */
    getResourceTypeInfo() {
      return new Promise((resolve, reject) => {
        this.$api.getResourceTypes().then((res) => {
          if (res.code === 0) {
            this.resourceTypeData = res.data
            console.log(res.data)
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  flex-direction: column;
  padding: 10px;
  .title-top {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      font-weight: bold;
      font-size: 18px;
      display: flex;
    }
  }
  .title-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .info {
      width: 90%;
      min-width: 1000px;
      display: flex;
      flex-wrap: warp;
      flex-flow: row wrap;
      div {
        width: 23%;
        margin-bottom: 10px;
      }
    }
    // .action {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: flex-end;
    //   justify-content: space-around;
    //   width: 33%;
    //   .action-text {
    //     display: flex;
    //   }
    // }
  }
}

/deep/ .tag-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 0 10px;
}

/deep/ .companyInfo {
  position: relative;
  padding: 15px;
  .info-box {
    .info-box-title {
      font-weight: bold;
    }
    div {
      display: flex;
      margin-bottom: 20px;
      span {
        width: 150px;
        text-align: right;
      }
      ant-input {
        width: 250px;
      }
    }
  }
  .info-img {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 10px;
    div {
      margin-bottom: 20px;
      display: flex;
      span {
        width: 100px;
        text-align: right;
      }
      img {
        width: 100%;
        height: 100%;
        // border-radius: 5px;
        // overflow: hidden;
      }
      div {
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }
}

.ant-input-disabled {
  color: rgba(0, 0, 0, 0.65) !important;
  background: #f5f7fb !important;
  // cursor: not-allowed !important;
}

.userListBox {
  padding: 15px;
}

.resource {
  padding: 15px;
  .resource_title {
    margin-bottom: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      margin-left: 10px;
      font-family: '.PingFang SC S0pxibold';
      font-weight: 600;
      font-size: 16px;
      text-align: left;
      color: #151515;
      /* 伪类 */
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -5px;
        transform: translateY(-50%);
        width: 4px;
        height: 20px;
        border-radius: 3px;
        background: #1890ff;
      }
    }
  }
}

.resource_box {
  display: grid;
  padding: 0px 0px;
  box-sizing: border-box;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  .item_information {
    height: 120px;
    // width: 190px;
    padding: 27px 29px 27px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    & > div:nth-of-type(1) {
      font-family: 'PingFangSC';
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      color: #525252;
      margin-bottom: 8px;
    }
    & > div:nth-of-type(2) {
      color: #292929ff;
      font-size: 16px;
      font-face: PingFangSC;
      font-weight: 500;
      letter-spacing: 0;
      span:nth-of-type(1) {
        font-size: 34px;
      }
      span:nth-of-type(3) {
        margin-left: 20px;
        cursor: pointer;
        width: 58px;
        height: 20px;
        border-radius: 4px;
        background-color: rgba(0, 89, 255, 0.1);
        font-family: 'PingFangSC';
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: left;
        color: #0059ff;
        padding: 3px;
      }
    }
    & > div:nth-of-type(3) {
      font-family: 'PingFangSC';
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      color: #bbbcbf;
    }
  }
}
</style>
