import { render, staticRenderFns } from "./addSource.vue?vue&type=template&id=86655b86&scoped=true&"
import script from "./addSource.vue?vue&type=script&lang=js&"
export * from "./addSource.vue?vue&type=script&lang=js&"
import style0 from "./addSource.vue?vue&type=style&index=0&id=86655b86&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86655b86",
  null
  
)

export default component.exports